import { useContext, useState } from 'react';
import { GithubAuthProvider, signInWithPopup, User } from "firebase/auth";
import { auth } from '../firebase/config';
import { AuthContext } from '../contexts/AuthContext';
import { SessionService } from '../services/session';
import { UserService } from '../services/user';


export const useLogin = () => {
    const [error, setError] = useState<any>(false);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useContext(AuthContext);

    const provider = new GithubAuthProvider();
    provider.addScope('repo');

    const login = async () => {
        setError(null);
        setIsPending(true);

        try {
            const res = await signInWithPopup(auth, provider);
            if (!res) {
                throw new Error("Could not complete signup");
            }

            const user: User = res.user;
            const credential = GithubAuthProvider.credentialFromResult(res);

            let githubToken = ""
            if (credential !== null && credential.accessToken !== undefined) {
                githubToken = credential.accessToken;
            }
            
            const idToken = await user.getIdToken();

            await SessionService.getSession(idToken, githubToken);

            const userExists = await UserService.userExists(user.uid);
            if (!userExists) {
                const { displayName, email, photoURL } = user;
                if (displayName && email && photoURL) {
                    const res = await UserService.createUser(
                        displayName,
                        email,
                        photoURL,
                        displayName,
                        idToken,
                    );

                    if (!res.ok) {
                        throw new Error("Could not create user: error from server");
                    }
                } else {
                    throw new Error("Could not create user: displayName, email or photoURL not available");
                }
                
            }

            dispatch({ type: "LOGIN", payload: {user, githubToken} });

            setIsPending(false);
        } catch (error:any) {
            console.log(error);
            setError(error.message);
            setIsPending(false);
        }
    };

    return { login, error, isPending };
}