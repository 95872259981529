import { useContext } from "react";
import { signOut } from "firebase/auth";
import { AuthContext } from "../contexts/AuthContext";
import { auth } from "../firebase/config";
import { SessionService } from "../services/session";

export const useLogout = () => {
    const { state, dispatch } = useContext(AuthContext);

    const logout = async () => {
        try {
            await signOut(auth);
            await SessionService.clearSession(state.user.accessToken);
            dispatch({ type: "LOGOUT" });
            console.log("user logged out");
        } catch (error:any) {
            console.log(error.message);
        }
    };

    return { logout };
}
