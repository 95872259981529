import { getDoc, doc } from 'firebase/firestore';
import { db } from './config';

export const getUserSites = async (user: any) => {
    try {
        const docRef = doc(db, "sites", user.uid);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {
            return(docSnap.data()['user_sites'])
        } else {
            return([]);
        }
    } catch(error) {
        console.log(error);
    }
}