import React, { useContext, useEffect, useState } from 'react'
import { Spacer, Table, Grid, Button } from '@geist-ui/core';
import { Plus } from '@geist-ui/icons';

import { AuthContext } from '../contexts/AuthContext';
import PageLayout from '../components/PageLayout';
import { useNavigate } from 'react-router-dom';
import { getUserSites } from '../firebase/getUserSites';

const env = process.env.NODE_ENV;
const sites_base_url = (env === "development") ? "http://localhost:8080/proxy/sites" :"https://easypages-test.web.app/proxy/sites"

export default function SitesPage() {
    const navigate = useNavigate();
    const [ sites, setSites ] = useState([]);
    const { state } = useContext(AuthContext);

    async function getData() {
        const data = await getUserSites(state.user);
        setSites(data);
    }

    useEffect(() => {
        getData();
    },[]);
    

    const addSiteHandler = () => {
        navigate("/app/sites/add");
    }

    const renderActions = (value: any, rowData: any, index: any) => {
        const viewHandler = () => {
            const { repo_owner, repo_name, branch_name, site_path } = rowData;
            const url = `${sites_base_url}/${repo_owner}/${repo_name}/${branch_name}/${site_path}/index.html`
            window.location.href = url;
        }
        const deleteHandler = () => {
            console.log("Delete");
        }
        return (
            <Grid.Container direction='row'>
                <Grid>
                    <Button type='success' scale={0.75} width="50px" onClick={viewHandler}>View</Button>
                </Grid>
                <Spacer width="10px" />
                <Grid>
                    <Button type='error' scale={0.75} width="50px" onClick={deleteHandler}>Delete</Button>
                </Grid>
            </Grid.Container>
        )
    }

    return (
        <PageLayout>
            <Grid.Container direction='row'>
                <Grid xs={4}>
                    <h4>Sites</h4>
                </Grid>
                <Grid xs={16}>
                    <Spacer />
                </Grid>
                <Grid xs={4} justify='flex-end'>
                    <Button icon={<Plus />} scale={0.5} onClick={addSiteHandler}>Add</Button>
                </Grid>
            </Grid.Container>
            <Table data={sites}>
                <Table.Column prop="site_name" label="Name" />
                <Table.Column prop="repo_owner" label="Repo Owner" />
                <Table.Column prop="repo_name" label="Repo" />
                <Table.Column prop="branch_name" label="Branch" />
                <Table.Column prop="site_path" label="Site Path" />
                <Table.Column prop="state" label="State" />
                <Table.Column prop="actions" label='' render={renderActions} />
            </Table>
        </PageLayout>
    )
}
