import React, { useContext } from 'react';
import { Page, Grid, Text, Spacer, Toggle, Tabs } from '@geist-ui/core';
import { AuthContext } from '../contexts/AuthContext';
import UserProfile from './UserProfile';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
    switchThemes(): void
}

export default function Header({ switchThemes }: HeaderProps) {
    const { state } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleNavigation = (val: string) => {
        navigate(`/app/${val}`);
    };

    return (
        <React.Fragment>
        <Page.Header width={"calc(100vw - 20px)"} paddingLeft={"10px"} paddingRight={"10px"} style={{position: "fixed", zIndex: 1, boxShadow:"0px -10px 20px black"}}>
            <Grid.Container>
                <Grid xs={8}>
                    <h2>Easy Pages</h2>
                </Grid>
                <Grid
                    xs={8}
                    direction='row'
                    alignItems='center'
                >
                    <Tabs hideDivider hideBorder initialValue='' onChange={handleNavigation} marginTop={"auto"}>
                        <Tabs.Item label="Home" value=''/>
                        {state.user !== null &&
                            <>
                            <Tabs.Item label="Sites" value='sites'/>
                            <Tabs.Item label="Profile" value='profile'/>
                            </>
                        }
                    </Tabs>
                </Grid>
                <Grid
                    xs={8} direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <Text small>Dark Mode</Text>
                    <Spacer />
                    <Toggle paddingTop={0} onChange={switchThemes} />
                    <Spacer />
                    <UserProfile />
                </Grid>
            </Grid.Container>
        </Page.Header>
        </React.Fragment>
    );
}
