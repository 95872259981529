import { User } from "firebase/auth";
import { AuthStateType } from "../contexts/AuthContext";

export interface AuthActionPayload {
    user: User
    githubToken: string
}

export type ActionType = 
    | { type: "LOGIN", payload: AuthActionPayload}
    | { type: "LOGOUT", payload?: null }
    | { type: "AUTH_IS_READY", payload: object | null};


export const authReducer = (state:AuthStateType, action:ActionType) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: action.payload.user, githubToken: action.payload.githubToken };
        case "LOGOUT":
            return { ...state, user: null};
        case "AUTH_IS_READY":
            return {...state, user: action.payload, authIsReady: true };
        default:
            return state;
    }
};
