
interface GetSessionRequest {
    idToken: string
    githubToken: string
}

interface ClearSessionRequest {
    idToken: string
}

const env = process.env.NODE_ENV;

const sessionServiceUrl = (env === "development") ? "http://localhost:8081/sessions" : "https://easypages-test.web.app/sessions"

export class SessionService {
    public static async getSession(idToken: string, githubToken: string): Promise<Response> {
        const headers: Headers = new Headers();
        headers.set('Content-Type', 'application/json');

        const reqBody: GetSessionRequest = {
            idToken: idToken,
            githubToken: githubToken
        };

        const request: RequestInfo = new Request(`${sessionServiceUrl}/get`, {
            method: 'POST',
            credentials: "include",
            headers: headers,
            body: JSON.stringify(reqBody),
        })

        return fetch(request);
    }

    public static async clearSession(idToken: string): Promise<Response> {
        const headers: Headers = new Headers();
        headers.set('Content-Type', 'application/json');

        const reqBody: ClearSessionRequest = {
            idToken: idToken,
        }

        const request: RequestInfo = new Request(`${sessionServiceUrl}/clear`, {
            method: 'POST',
            credentials: "include",
            headers: headers,
            body: JSON.stringify(reqBody),
        })

        return fetch(request);
    }
}