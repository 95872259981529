// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAfmpt2A-EpwyHYBSXx_fysdY0wFNot6Z4",
    authDomain: "easypages-test.firebaseapp.com",
    projectId: "easypages-test",
    storageBucket: "easypages-test.appspot.com",
    messagingSenderId: "754372033592",
    appId: "1:754372033592:web:9bc1e59271509afea0bacd",
    measurementId: "G-E48P9B20QR"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth();
const db = getFirestore();

export { auth, db };
