import React, { useContext } from 'react'
import { Loading, User, Popover, Link } from '@geist-ui/core';

import { useLogout } from '../hooks/useLogout';
import SignInButton from './SignInButton';
import { AuthContext } from '../contexts/AuthContext';


export default function UserProfile() {
    const { state } = useContext(AuthContext);
    const { logout } = useLogout();

    
    if (!state.authIsReady) {
        return <Loading />;
    }

    if (!state.user) {
        return <SignInButton />;
    }

    const user = state.user;
    
    const popoverContent = () => (
        <>
            <Popover.Item title>
                <span>User</span>
            </Popover.Item>
            <Popover.Item>
                <Link>Profile</Link>
            </Popover.Item>
            <Popover.Item line />
            <Popover.Item>
                <span onClick={logout} style={{cursor: 'pointer'}}>Logout</span>
            </Popover.Item>
        </>
    );

    return(
        <Popover content={popoverContent}>
            <User src={user.photoURL} name={user.displayName} style={{cursor: 'pointer'}}/>
        </Popover>
    )
}
