import React, { ReactNode } from 'react'
import { Page, Spacer } from '@geist-ui/core';

export default function PageLayout({ children }: { children: ReactNode}) {
    return (
        <Page width={"auto"}>
            <Page.Content paddingTop={0}>
                <Spacer height={"90px"} />
                { children }
            </Page.Content>
        </Page>
    )
}
