import React from 'react'
import { Page, Grid, Loading } from '@geist-ui/core'

export default function LoadingPage() {
    return (
        <Page>
            <Grid.Container justify='center' height={"calc(100vh - 125px)"}>
                <Grid xs={24}>
                    <Loading spaceRatio={2.5} />
                </Grid>
            </Grid.Container>
        </Page>
    )
}
