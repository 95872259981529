import React from 'react'
import { ButtonDropdown } from '@geist-ui/core'

import { useLogin } from '../hooks/useLogin'

export default function SignInButton() {
    const { login } = useLogin();
    
    return (
        <ButtonDropdown type='secondary' auto={true} scale={0.5}>
            <ButtonDropdown.Item main onClick={login}>Github Sign In</ButtonDropdown.Item>
            <ButtonDropdown.Item>Google Sign In</ButtonDropdown.Item>
        </ButtonDropdown>
    )
}
