import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, GeistProvider, useToasts } from '@geist-ui/core';
import { HomePage } from './pages/Home';
import Header from './components/Header';
import SitesPage from './pages/Sites';
import ProfilePage from './pages/Profile';
import { ProtectedRoute } from './components/ProtectedRoute';
import AddSitePage from './pages/AddSite';

const App = () => {
  const [themeType, setThemeType] = useState('light')
  const { setToast } = useToasts();

  const switchThemes = () => {
    setThemeType(last => (last === 'dark' ? 'light' : 'dark'))
  }

  const displayError = (msg: string): void => {
    setToast({
      text: msg,
      type: 'error',
      delay: 5000,
    });
  }

  return (
    <GeistProvider themeType={themeType}>
      <CssBaseline />
      <Header switchThemes={switchThemes}/>
      <Routes>
        <Route path="/app/" element={<HomePage />} />
        <Route
          path='/app/sites'
          element={
            <ProtectedRoute>
              <SitesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/app/sites/add'
          element={
            <ProtectedRoute>
              <AddSitePage displayError={displayError}/>
            </ProtectedRoute>
          }
        />
        <Route
          path='/app/profile'
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </GeistProvider>
  )
}

export default App;
