import React from 'react';

import { Grid, Input, Text } from '@geist-ui/core';
import { Github } from '@geist-ui/icons';

import { FormValues } from '../pages/AddSite';


interface GitHubDeployFormProps {
    formValues: FormValues
    handleFieldChange(fieldId:string, value:string): void
}

export default function GitHubDeployForm({ formValues, handleFieldChange }: GitHubDeployFormProps): JSX.Element {

    return(
        <Grid.Container direction='column'>
            <Grid xs={6}>
                <Input value={formValues.site_name} onChange={(e) => handleFieldChange('site_name', e.target.value)} crossOrigin={"false"} placeholder='e.g. My Cool Site'>
                    <Text p>Site Name</Text>
                </Input>
            </Grid>
            <Grid xs={6}>
                <Input value={formValues.repo_owner} onChange={(e) => handleFieldChange('repo_owner', e.target.value)} crossOrigin={"false"} placeholder='e.g. PeakBI' icon={<Github />}>
                    <Text p>Repo Owner</Text>
                </Input>
            </Grid>
            <Grid xs={6}>
                <Input value={formValues.repo_name} onChange={(e) => handleFieldChange('repo_name', e.target.value)} crossOrigin={"false"} placeholder='e.g. peak-vscode' icon={<Github />}>
                    <Text p>Repo</Text>
                </Input>
            </Grid>
            <Grid xs={6}>
                <Input value={formValues.branch_name} onChange={(e) => handleFieldChange('branch_name', e.target.value)} crossOrigin={"false"} placeholder='e.g. main' icon={<Github />}>
                    <Text p>Branch</Text>
                </Input>
            </Grid>
            <Grid xs={6}>
                <Input value={formValues.site_path} onChange={(e) => handleFieldChange('site_path', e.target.value)} crossOrigin={"false"} placeholder='e.g. docs' icon={<Github />}>
                    <Text p>Repo Site Path</Text>
                </Input>
            </Grid>
        </Grid.Container>
    )
}
