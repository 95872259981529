import React, { Dispatch, createContext, useEffect, useReducer } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../firebase/config";
import { authReducer, ActionType } from "../reducers/authReducer";

export interface AuthStateType {
    user: any | null
    authIsReady: boolean
    githubToken: string
}

interface AuthStateContextType {
    state: AuthStateType
    dispatch: Dispatch<ActionType>
}

const initialState: AuthStateType = {
    user: null,
    authIsReady: false,
    githubToken: ""
};

export const AuthContext = createContext<AuthStateContextType>({} as AuthStateContextType);

const AuthContextProvider = ({ children }:{ children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user:any|null) => {
            dispatch({ type: "AUTH_IS_READY", payload: user });
        });
        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;