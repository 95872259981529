import React, { useContext } from 'react';
import { Card } from '@geist-ui/core';

import { AuthContext } from '../contexts/AuthContext';
import PageLayout from '../components/PageLayout';
import LoadingPage from './Loading';


export const HomePage = () => {
  const { state } = useContext(AuthContext);

  if (!state.authIsReady) {
    return <LoadingPage />
  }

  return (
    <PageLayout>
      <Card shadow>
          <h4>Welcome to Easy Pages</h4>
          <p>
            Easy Pages is designed to make it simple to deploy static websites such as docsites for private use.
          </p>
        </Card>
    </PageLayout>
  );
}
