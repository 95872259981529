import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";
import LoadingPage from "../pages/Loading";

export const ProtectedRoute = ({ children }:any) => {
    const { state } = useContext(AuthContext);

    if (!state.authIsReady) {
        return <LoadingPage />
    }

    if (!state.user) {
        return <Navigate to="/" />;
    }

    return children;
}