import React, { useState, useContext } from 'react'
import { Button, Fieldset } from '@geist-ui/core'
import { Zap } from '@geist-ui/icons'

import { AuthContext } from '../contexts/AuthContext';
import PageLayout from '../components/PageLayout'
import GitHubDeployForm from '../components/GitHubDeployForm';


interface AddSitePageProps {
    displayError(msg: string): void
}

export interface FormValues {
    site_name: string
    repo_owner: string
    repo_name: string
    branch_name: string
    site_path: string
}

interface DeployRequestBody {
    site_name: string
    repo_owner: string
    repo_name: string
    branch_name: string
    site_path: string
    auth_token: string
}

interface DeployResponseBody {
    success: boolean
}

const initialFormValues: FormValues = {
    site_name: '',
    repo_owner: '',
    repo_name: '',
    branch_name: '',
    site_path: '',
}

const env = process.env.NODE_ENV;

const url = (env === "development") ? "http://localhost:8082/site/create" : "https://easypages-test.web.app/site/create"

async function handleErrors(response: Response): Promise<Response> {
    if(!response.ok) {
        throw new Error(`${response.statusText}: ${await response.text()}`);
    }

    return response;
}

export async function deploy(requestBody: DeployRequestBody, accessToken:string): Promise<DeployResponseBody> {
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `${accessToken}`)

    const request: RequestInfo = new Request(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
    });

    return fetch(request).then(handleErrors).then(res => res.json()).then(res => res as DeployResponseBody);
}

export default function AddSitePage({ displayError }: AddSitePageProps) {
    const [loading, setLoading] = useState(false);
    const { state } = useContext(AuthContext);
    const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

    const handleFieldChange = (fieldId:string, value:string) => {
        setFormValues({ ...formValues, [fieldId]: value });
    };

    const deployHandler = async () => {
        setLoading(true);
        try {
            if (state.authIsReady && state.user != null) {
                const deployRequestBody: DeployRequestBody = {
                    ...formValues,
                    auth_token: state.githubToken
                };
                await deploy(deployRequestBody, state.user.accessToken);
            } else {
                throw new Error("Could not retrieve auth token");
            }
            
        } catch(error: any) {   
            displayError(error.message);
        }
        setLoading(false);
    }

    return (
        <PageLayout>
            <Fieldset.Group value='simple'>
                <Fieldset label="simple">
                    <GitHubDeployForm formValues={formValues} handleFieldChange={handleFieldChange}/>
                    <Fieldset.Footer>
                        A strightforward deployment
                        <Button loading={loading} icon={<Zap/>} onClick={deployHandler}>Deploy</Button>
                    </Fieldset.Footer>
                </Fieldset>
                <Fieldset label="less simple">
                </Fieldset>
            </Fieldset.Group>
        </PageLayout>
    )
}
