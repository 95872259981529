import { doc, getDoc } from 'firebase/firestore';

import { db } from '../firebase/config';

interface CreateUserRequest {
    displayName: string
    email: string
    photoURL: string
    username: string
}

const env = process.env.NODE_ENV;

const userServiceURL = (env === "development") ? "http://localhost:8083/user" : "https://easypages-test.web.app/user"

export class UserService {
    public static async createUser(displayName: string, email:string, photoURL:string, username:string, idToken:string): Promise<Response> {
        const headers: Headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', idToken);

        const reqBody: CreateUserRequest = {
            displayName: displayName,
            email: email,
            photoURL: photoURL,
            username: username,
        }

        const request: RequestInfo = new Request(`${userServiceURL}/create`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(reqBody),
        })

        return fetch(request);
    }

    public static async userExists(uid: string): Promise<boolean> {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        return docSnap.exists();
    }
}